import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import env from "../config/enviroment";
import { Typography, Box, Button, CircularProgress } from "@mui/material";

function DownloadReports() {
  const [loading, setLoading] = useState(false);

  const handleDownloadReport = async (endpoint, fileName) => {
    setLoading(true);
    try {
      const response = await axios.get(`${env.API_URL}${endpoint}`, {
        headers: {
          "api-key": env.API_KEY_FABISAN,
        },
        responseType: "blob", // Manejar la respuesta como un archivo
      });

      // Crear un enlace de descarga en el navegador
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      swal("Reporte descargado correctamente", "", "success");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "No se pudo descargar el reporte";
      swal("Error", errorMessage, "error");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="secondary" gutterBottom>
        Descargar Reportes
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          handleDownloadReport(
            "/reports",
            "reporte_rechazados_sin_creditos.xlsx"
          )
        }
        disabled={loading}
        sx={{ mb: 2 }}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          "Reporte de usuarios rechazados sin créditos otorgados"
        )}
      </Button>

      {/* Puedes agregar más botones aquí para otros reportes */}
    </Box>
  );
}

export default DownloadReports;
