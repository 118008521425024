export const testing = [
  "fabian@finnix.ai",
];

export const supervisor = [
  "fabian@finnix.ai",
  "diego@finnix.ai",
];

export const admin = [
  "carolina@finnix.ai",
  "diego@finnix.ai",
  "fabian@finnix.ai",
];

export const basicAccess = [
  "carolina@finnix.ai",
  "diego@finnix.ai",
  "fabian@finnix.ai",
  "rafael@finnix.ai",
  "paulina@finnix.ai",
  "esanchez@finnix.ai",
  "cbauer@finnix.ai",
  "hola@finnix.ai"
];
