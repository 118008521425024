import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import env from "../config/enviroment";
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Paper,
} from "@mui/material";

function CrearRenovacionExcepcional() {
  const [customerId, setCustomerId] = useState("");
  const [ciclo, setCiclo] = useState("");
  const [creditLevel, setCreditLevel] = useState("");
  const [softCreditClientId, setSoftCreditClientId] = useState("");
  const [numberOfPayments, setNumberOfPayments] = useState("");
  const [CAT, setCAT] = useState("");
  const [annualInterestRate, setAnnualInterestRate] = useState("");
  const [moratoriumInterestRate, setMoratoriumInterestRate] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (setter) => (event) => {
    setter(event.target.value);
  };
          
  const handleCreateRenovation = async () => {
    setLoading(true); 
    try {
      const body = {
        customerId: parseInt(customerId, 10),
        ciclo: parseInt(ciclo, 10),
        creditLevel: parseInt(creditLevel, 10),
        softCreditClientId: parseInt(softCreditClientId, 10),
        numberOfPayments: parseInt(numberOfPayments, 10),
        frequency: "semanal",
        CAT: parseFloat(CAT),
        annualInterestRate: parseFloat(annualInterestRate),
        moratoriumInterestRate: parseFloat(moratoriumInterestRate),
        amount: parseInt(amount, 10),
      };
      const response = await axios.post(
        `${env.API_URL}/softcredit/renovationApplication/${customerId}?outrange=true`,
        body,
        {
          headers: {
            "api-key": env.API_KEY_FABISAN,
          },
        }
      ); 
      console.log("Response:", response.data);
      if (response.status === 200) {
        const { softCreditLoanId } = response.data;
        swal({
          title: "Renovación creada correctamente",
          text: `ID del préstamo: ${softCreditLoanId}`,
          icon: "success",
          buttons: {
            catch: {
              text: "Ver Préstamo",
              value: "view",
            },
            cancel: "Cerrar",
          },
        }).then((value) => {
          if (value === "view") {
            window.open(`https://softcredito.com/produccion/TRUECAPITAL/app/solicitudes/update/${softCreditLoanId}`, "_blank");
          }
        });
      } else {
        swal("Error", "No se pudo crear la renovación", "error");
      }
      setCustomerId(""); 
      setCiclo("");
      setCreditLevel("");
      setSoftCreditClientId("");
      setNumberOfPayments("");
      setCAT("");
      setAnnualInterestRate("");
      setMoratoriumInterestRate("");
      setAmount("");
    } catch (error) {
      swal("Error", "No se pudo crear la renovación", "error");
      console.error("Error:", error);
    } finally {
      setLoading(false); 
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleCreateRenovation();
    }
  };

  const isFormComplete = () => {
    return (
      customerId !== "" &&
      ciclo !== "" &&
      creditLevel !== "" &&
      softCreditClientId !== "" &&
      numberOfPayments !== "" &&
      CAT !== "" &&
      annualInterestRate !== "" &&
      moratoriumInterestRate !== "" &&
      amount !== ""
    );
  };

  const exampleData = {
    customerId: 7,
    ciclo: 2,
    creditLevel: 3,
    softCreditClientId: 2652,
    numberOfPayments: 10,
    CAT: 69867.86,
    Monto: 2000,
    annualInterestRate: 602.25,
    moratoriumInterestRate: 300,
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Crear Renovación Excepcional
          </Typography>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <TextField
                label="CustomerId"
                type="number"
                variant="outlined"
                value={customerId}
                onChange={handleFieldChange(setCustomerId)}
                onKeyPress={handleKeyPress}
                fullWidth
                sx={{ mb: 1 }}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Ciclo"
                type="number"
                variant="outlined"
                value={ciclo}
                onChange={handleFieldChange(setCiclo)}
                fullWidth
                sx={{ mb: 1 }}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Nivel de Crédito"
                type="number"
                variant="outlined"
                value={creditLevel}
                onChange={handleFieldChange(setCreditLevel)}
                fullWidth
                sx={{ mb: 1 }}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <TextField
                label="SoftcreditClientId"
                type="number"
                variant="outlined"
                value={softCreditClientId}
                onChange={handleFieldChange(setSoftCreditClientId)}
                fullWidth
                sx={{ mb: 1 }}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Número de Pagos"
                type="number"
                variant="outlined"
                value={numberOfPayments}
                onChange={handleFieldChange(setNumberOfPayments)}
                fullWidth
                sx={{ mb: 1 }}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <TextField
                label="CAT"
                type="number"
                inputProps={{ step: "0.01" }}
                variant="outlined"
                value={CAT}
                onChange={handleFieldChange(setCAT)}
                fullWidth
                sx={{ mb: 1 }}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Tasa de Interés Anual"
                type="number"
                inputProps={{ step: "0.01" }}
                variant="outlined"
                value={annualInterestRate}
                onChange={handleFieldChange(setAnnualInterestRate)}
                fullWidth
                sx={{ mb: 1 }}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Tasa de Interés Moratorio"
                type="number"
                inputProps={{ step: "0.01" }}
                variant="outlined"
                value={moratoriumInterestRate}
                onChange={handleFieldChange(setMoratoriumInterestRate)}
                fullWidth
                sx={{ mb: 1 }}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Monto"
                type="number"
                variant="outlined"
                value={amount}
                onChange={handleFieldChange(setAmount)}
                fullWidth
                sx={{ mb: 1 }}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateRenovation}
            disabled={loading || !isFormComplete()} 
            sx={{ mt: 2 }}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : "Crear Renovación"}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Ejemplo de Renovación
          </Typography>
          <Paper elevation={3} sx={{ p: 2 }}>
            <pre>{JSON.stringify(exampleData, null, 2)}</pre>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}

export default CrearRenovacionExcepcional;
