import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import env from "../config/enviroment";
import { TextField, Button, Typography, Box, CircularProgress } from '@mui/material';

function NuovoPay() {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleUnlock = async () => {
    setLoading(true);
    try {
      const response = await axios.patch(
        `${env.API_URL}/nuovopay/unlock/${phone}`,
        null,
        {
          headers: {
            'api-key': env.API_KEY_FABISAN
          }
        }
      );
      if (response.status === 200 && response.data.status) {
        swal("Celular desbloqueado correctamente", "", "success");
      } else {
        swal("Error", "No se pudo desbloquear el celular", "error");
      }
    } catch (error) {
      let errorMessage = "No se pudo desbloquear el celular";

      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = error.response.data || "Debe proporcionar un número de teléfono o un deviceId";
            break;
          case 422:
            errorMessage = "El deviceId no se encuentra enrolado en Nuovo";
            break;
          case 500:
            errorMessage = "No se encontró el customer";
            break;
          default:
            errorMessage = error.response.data?.error || "Error desconocido";
        }
      }

      swal("Error", errorMessage, "error");
    } finally {
      setLoading(false);
      setPhone(""); 
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUnlock();
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="secondary" gutterBottom>Desbloquear Celular</Typography>
      <TextField
        label="Ingrese teléfono"
        variant="outlined"
        value={phone}
        onChange={handlePhoneChange}
        onKeyPress={handleKeyPress}
        fullWidth
        sx={{ mb: 2 }}
        disabled={loading} 
      />
      <Button variant="contained" color="primary" onClick={handleUnlock} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : "Desbloquear Celular"}
      </Button>
    </Box>
  );
}

export default NuovoPay;
