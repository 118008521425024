import { useUser } from "./UserContext";
const PrivateRoute = ({ element, allowedEmails }) => {
  const { user } = useUser();

  if (user && allowedEmails.some(email => user.email.includes(email))) {
    return element;
  } else {
    return null;
  }
};

export default PrivateRoute;
