import React, { useState } from "react";
import axios from "axios";
import env from "../config/enviroment";
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
  Paper,
  Grid,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";

function NextCreditProjection() {
  const [customerId, setCustomerId] = useState("");
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projectionNext, setProjectionNext] = useState(false);
  const [projectionNextLiquidate, setProjectionNextLiquidate] = useState(true); // Predeterminado a true

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Reemplaza cualquier cosa que no sea un dígito
    setCustomerId(value);
  };

  const handleCheckboxChange = (type) => {
    if (type === "next") {
      setProjectionNext(true);
      setProjectionNextLiquidate(false);
    } else if (type === "nextLiquidate") {
      setProjectionNext(false);
      setProjectionNextLiquidate(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResponse(null); // Borra la respuesta anterior inmediatamente

    if (!projectionNext && !projectionNextLiquidate) {
      setError("Debe seleccionar una opción.");
      setLoading(false);
      return;
    }

    let url = `${env.API_URL}/softcredit/renovationApplicationdata/${customerId}`;
    if (projectionNext) {
      url += "?projection=next";
    } else if (projectionNextLiquidate) {
      url += "?projection=next&liquidate=true";
    }

    try {
      const res = await axios.get(url, {
        headers: {
          "api-key": env.API_KEY_FABISAN,
        },
      });
      setResponse({ ...res.data, customerId });
      setCustomerId(""); // Borra el texto después de enviar
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setResponse(null); // Borra la respuesta anterior inmediatamente
      handleSubmit(event);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="secondary" gutterBottom>
        Proyección Próximo Crédito
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          value={customerId}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="Customer ID"
          fullWidth
          sx={{ mb: 2 }}
        />
        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={projectionNextLiquidate}
                onChange={() => handleCheckboxChange("nextLiquidate")}
              />
            }
            label="Liquida hoy"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={projectionNext}
                onChange={() => handleCheckboxChange("next")}
              />
            }
            label="Se pone al día y paga a término"
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? "Loading..." : "Consultar"}
        </Button>
      </form>
      {response && (
        <Paper sx={{ mt: 3, p: 2 }}>
          <Typography variant="h6">Próximo Crédito:</Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Customer ID:</strong> {response.customerId}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Credit Level:</strong> {response.creditLevel}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Soft Credit Client ID:</strong>{" "}
                {response.softCreditClientId} {" "}
                <Link
                  href={`https://softcredito.com/produccion/TRUECAPITAL/app/clientes/update/${response.softCreditClientId}`}
                  target="_blank"
                >
                  Ver en Soft
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Number of Payments:</strong> {response.numberOfPayments}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>CAT:</strong> {response.CAT.toFixed(2)}%
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Min Amount:</strong> ${response.minAmount}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Max Amount:</strong> ${response.maxAmount}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Annual Interest Rate:</strong>{" "}
                {response.annualInterestRate.toFixed(2)}%
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Moratorium Interest Rate:</strong>{" "}
                {response.moratoriumInterestRate.toFixed(2)}%
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
      {error && (
        <Box sx={{ mt: 3, color: "red" }}>
          <Typography variant="h6">Error:</Typography>
          <Typography>{error}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default NextCreditProjection;
