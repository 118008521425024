import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import env from "../config/enviroment";
import { TextField, Button, Typography, Box, CircularProgress } from '@mui/material';

function GenerateClabe() {
  const [customerId, setCustomerId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCustomerIdChange = (event) => {
    setCustomerId(event.target.value);
  };

  const handleGenerateClabe = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${env.API_URL}/loan/customer/${customerId}`,
        {
          headers: {
            'api-key': env.API_KEY_FABISAN
          }
        }
      );
      console.log("Response:", response.data);
      if (response.status === 200 && Object.keys(response.data).length !== 0) {
        swal("CLABE generada correctamente", "", "success");
      } else {
        swal("Error", "Cliente no encontrado o no se pudo generar la CLABE", "error");
      }
      setCustomerId("");
    } catch (error) {
      const errorMessage = error.response?.data?.message || "No se pudo generar la CLABE";
      swal("Error", errorMessage, "error");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleGenerateClabe();
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="secondary" gutterBottom>Generar CLABE</Typography>
      <TextField
        label="Ingrese customerId"
        variant="outlined"
        value={customerId}
        onChange={handleCustomerIdChange}
        onKeyPress={handleKeyPress}
        fullWidth
        sx={{ mb: 2 }}
        disabled={loading} 
      />
      <Button variant="contained" color="primary" onClick={handleGenerateClabe} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : "Generar CLABE"}
      </Button>
    </Box>
  );
}

export default GenerateClabe;
