import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import env from "../config/enviroment";
import { TextField, Button, Typography, Box, List, ListItem, ListItemText, Paper, Divider } from '@mui/material';

function Users() {
  const [blacklistCustomerId, setBlacklistCustomerId] = useState("");
  const [deleteCustomerId, setDeleteCustomerId] = useState("");
  const [blockedUsers, setBlockedUsers] = useState([]);

  const handleBlacklistCustomerIdChange = (event) => {
    const inputNumber = event.target.value.replace(/\D/, "").slice(0, 25);
    setBlacklistCustomerId(inputNumber);
  };

  const handleDeleteCustomerIdChange = (event) => {
    const input = event.target.value.toUpperCase().slice(0, 25);
    setDeleteCustomerId(input);
  };

  const handleBlacklistSubmit = () => {

    axios
      .post(
        `${env.API_URL}/blacklist/${blacklistCustomerId}`,
        {},
        {
          headers: {
            'api-key': env.API_KEY_FABISAN,
          }
        }
      )
      .then(() => {
        swal("Agregado correctamente", "Se agregó correctamente a la blacklist", "success");
        fetchBlockedUsers();
        setBlacklistCustomerId(""); 
      })
      .catch((error) => {
        swal("Error", "Usuario no encontrado", "error");
        console.error("Error:", error);
        setBlacklistCustomerId(""); 
      });
  };

  const handleDeleteSubmit = () => {
    axios
      .delete(`${env.API_URL}/blacklist/customer/${deleteCustomerId}`,
        {
          headers: {
            'api-key': env.API_KEY_FABISAN 
          }
        })
      .then(() => {
        swal("Eliminado correctamente", "Se eliminó correctamente de la blacklist", "success");
        fetchBlockedUsers();
        setDeleteCustomerId(""); 
      })
      .catch((error) => {
        swal("Error", "No se encontró el customerId en la blacklist", "error");
        console.error("Error:", error);
        setDeleteCustomerId(""); 
      });
  };

  const fetchBlockedUsers = () => {
    axios
      .get(`${env.API_URL}/blacklist/`,
        {
          headers: {
            'api-key': env.API_KEY_FABISAN 
          }
        })
      .then((response) => {
        setBlockedUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blocked users:", error);
      });
  };

  useEffect(() => {
    fetchBlockedUsers();
  }, []);

  const handleKeyPress = (event, action) => {
    if (event.key === "Enter") {
      action();
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="secondary" gutterBottom>BlackList</Typography>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6">Ingrese customerId a Bloquear:</Typography>
        <TextField
          variant="outlined"
          value={blacklistCustomerId}
          onChange={handleBlacklistCustomerIdChange}
          onKeyPress={(event) => handleKeyPress(event, handleBlacklistSubmit)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleBlacklistSubmit}>
          Enviar a Blacklist
        </Button>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6">Ingrese customerId a Liberar:</Typography>
        <TextField
          variant="outlined"
          value={deleteCustomerId}
          onChange={handleDeleteCustomerIdChange}
          onKeyPress={(event) => handleKeyPress(event, handleDeleteSubmit)}
          fullWidth
          multiline
          rows={3}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="secondary" onClick={handleDeleteSubmit}>
          Quitar de Blacklist
        </Button>
      </Box>

      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" color="secondary" gutterBottom>Usuarios Bloqueados:</Typography>
        <Divider sx={{ mb: 2 }} />
        <List>
          {blockedUsers.map((user) => (
            <ListItem key={user.id} sx={{ mb: 1 }}>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    <strong>Customer ID:</strong> {user.customerId}, <strong>PHONE:</strong> {user.PHONE}, <strong>CURP:</strong> {user.CURP}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
}

export default Users;
