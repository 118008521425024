import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import env from "../config/enviroment";
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';

function UnrollmentManualy() {
  const [softCreditClientId, setSoftCreditClientId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCustomerIdChange = (event) => {
    setSoftCreditClientId(event.target.value);
  };

  const handleEnrollmentCheck = async () => {
    if (!softCreditClientId) {
      swal("Error", "El campo softCreditClientId no puede estar vacío", "error");
      return;
    }

    setLoading(true); 

    const url = `${env.API_URL}/nuovopay/unregister?liquidated=true&softCreditClientId=${softCreditClientId}`;

    const headers = {
      'api-key': env.API_KEY_FABISAN,
      'Content-Type': 'application/json'
    };

    try {
      const response = await axios.post(url, {}, { headers });
      const unrolled = response.data;
      if (unrolled) {
        swal("Dispositivo eliminado correctamente", "", "success").then(() => {
          setSoftCreditClientId("");  
        });
      } else {
        swal("Sin información de dispositivo", "", "error").then(() => {
          setSoftCreditClientId("");  
        });
      }
    } catch (error) {
      console.error("Error fetching device information: ", error);
      if (error.response) {
        const errorMessage = error.response.data.error || error.response.data || "Error desconocido";
        console.error("Error response data:", errorMessage);
        swal("Error", errorMessage, "error").then(() => {
          setSoftCreditClientId("");  
        });
      } else if (error.request) {
        console.error("No se recibió respuesta del servidor:", error.request);
        swal("Error", "No se recibió respuesta del servidor. Por favor, intente nuevamente más tarde.", "error").then(() => {
          setSoftCreditClientId("");  
        });
      } else {
        console.error("Error en la configuración de la solicitud:", error.message);
        swal("Error", `Error al configurar la solicitud: ${error.message}`, "error").then(() => {
          setSoftCreditClientId("");  
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleEnrollmentCheck();
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="secondary" gutterBottom>
        Eliminar Dispositivo de Nuovopay
      </Typography>
      <TextField
        label="Ingrese softCreditClientId"
        variant="outlined"
        value={softCreditClientId}
        onChange={handleCustomerIdChange}
        onKeyPress={handleKeyPress}
        fullWidth
        sx={{ mb: 2 }}
        disabled={loading} 
      />
      <Button variant="contained" color="primary" onClick={handleEnrollmentCheck} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : "Verificar"}
      </Button>
    </Box>
  );
}

export default UnrollmentManualy;
