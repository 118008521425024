import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useUser } from "../services/UserContext"; // Importa el contexto

function Dashboard() {
  const navigate = useNavigate();
  const { setUser } = useUser();

  const onSuccess = (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse?.credential);
    setUser(decoded);
    if (decoded.email.includes("@finnix.ai")) {
      navigate("/admin");
    } else {
      navigate("/Blocked");
    }
  };

  const onError = () => {
    console.log('Login Failed');
  };

  return (
    <div>
      <h2>Login</h2>
      <GoogleLogin
        onSuccess={onSuccess}
        onError={onError}
        useOneTap
      />
    </div>
  );
}

export default Dashboard;
