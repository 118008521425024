import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import env from "../config/enviroment";
import { TextField, Button, Typography, Box, CircularProgress } from '@mui/material';

function SendEmailApproved() {
  const [customerId, setCustomerId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCustomerIdChange = (event) => {
    setCustomerId(event.target.value);
  };

  const handleSendEmail = async () => {
    setLoading(true); // Iniciar el indicador de carga
    try {
      const response = await axios.get(
        `${env.API_URL}/nuovopay/verify/${customerId}`,
        {
          headers: {
            'api-key': env.API_KEY_FABISAN
          }
        }
      );
      console.log("Response:", response.data);
      if (response.status === 200) {
        swal("Mail generado correctamente", "", "success");
      } else {
        swal("Error", "No se pudo generar el mail", "error");
      }
      setCustomerId(""); // Limpiar el campo de entrada
    } catch (error) {
      swal("Error", "No se pudo generar el mail", "error");
      console.error("Error:", error);
    } finally {
      setLoading(false); // Detener el indicador de carga
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendEmail();
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="secondary" gutterBottom>Enviar Email de Aprobación (Enrolamiento: True)</Typography>
      <TextField
        label="Ingrese customerId"
        variant="outlined"
        value={customerId}
        onChange={handleCustomerIdChange}
        onKeyPress={handleKeyPress}
        fullWidth
        sx={{ mb: 2 }}
        disabled={loading} // Deshabilitar campo de texto mientras está cargando
      />
      <Button variant="contained" color="primary" onClick={handleSendEmail} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : "Enviar Email"}
      </Button>
    </Box>
  );
}

export default SendEmailApproved;
